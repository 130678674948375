<template lang="html">
    <section class="automatically-box -demo">
        <Waypoint id="line">
            <div class="detail1">
                <Vue3Lottie v-if="animationState.line" :animationData="LineBlue" :height="auto" width="100%" direction="forward" />
            </div>
        </Waypoint>
        <!-- <img src="/img/icons/detail-line1.svg" alt="" class="detail1"> -->
        <Waypoint id="line2">
            <div class="detail2">
                <Vue3Lottie v-if="animationState.line2" :animationData="LineBlue2" :height="auto" width="100%" direction="forward" />
            </div>
        </Waypoint>
        <!-- <img src="/img/icons/detail-line2.svg" alt="" class="detail2"> -->
        <div class="container grid-2 demo">
            <div class="content demo grid">
                <h1 class="title-big -blueDarkC demo">{{$t('demo.intro')}}</h1>
                <br>
                <p class="-blueDarkC dsp">{{$t('demo.text')}}</p>
                <ul class="listado-demo-1">
                    <li class="-blueDarkC">{{$t('demo.check1')}}</li>
                    <li class="-blueDarkC">{{$t('demo.check2')}}</li>
                    <li class="-blueDarkC">{{$t('demo.check3')}}</li>
                    <li class="-blueDarkC">{{$t('demo.check4')}}</li>
                </ul>
                <br><br>
                <p class="-blueDarkC demo2">{{$t('demo.text2')}}</p>
                <ul class="listado-demo">
                    <li class="-blueDarkC">{{$t('demo.li1')}}</li>
                    <li class="-blueDarkC">{{$t('demo.li2')}}</li>
                    <li class="-blueDarkC">{{$t('demo.li3')}}</li>
                    <li class="-blueDarkC">{{$t('demo.li4')}}</li>
                </ul>
                <br><br>
                <p class="-blueDarkC compromiso">{{$t('demo.text3')}}</p>

            </div>
            <template v-if="locale == 'es'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget" data-url="https://calendly.com/r-sami-demo/gratuita?primary_color=00cfc9" style="min-width:320px;width:100%;height:935px;"></div>
                    <component :is="'script'" ctype="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></component>
                </div>

            </template>

            <template v-if="locale == 'en'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget"
                        data-url="https://calendly.com/r-sami-demo/r-sami-free-demo"
                        style="min-width:320px;height:630px;"></div>
                    <component :is="'script'" ctype="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js" async></component>

                </div>
            </template>

            <template v-if="locale == 'nl'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget"
                        data-url="https://calendly.com/r-sami-demo/r-sami-free-demo"
                        style="min-width:320px;height:630px;"></div>
                    <component :is="'script'" ctype="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js" async></component>

                </div>
            </template>
            <template v-if="locale == 'fr'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget"
                        data-url="https://calendly.com/r-sami-demo/r-sami-free-demo"
                        style="min-width:320px;height:630px;"></div>
                    <component :is="'script'" ctype="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js" async></component>

                </div>
            </template>

            <template v-if="locale == 'it'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget"  data-url="https://calendly.com/r-sami-demo/demo-gratuita-di-r-sami" style="min-width:320px;height:630px;"></div>                    <component :is="'script'" ctype="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js"  async></component>

                </div>
            </template>
            <template v-if="locale == 'de'">
                <div class="content demo2 grid">
                    <div class="calendly-inline-widget"
                        data-url="https://calendly.com/r-sami-demo/r-sami-free-demo"
                        style="min-width:320px;height:630px;"></div>
                    <component :is="'script'" ctype="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js" async></component>

                </div>
            </template>
        </div>

    </section>

</template>

<script lang="js">

    import { Vue3Lottie } from 'vue3-lottie'
    import 'vue3-lottie/dist/style.css'
    import LineBlue from '@/lotties/line_1_blue.json'
    import LineBlue2 from '@/lotties/line_2_blue.json'

    import { Waypoint } from 'vue-waypoint'

    import { useI18n } from 'vue-i18n'

    export default {
        name: 'home',
        props: [],
        components: {
            Waypoint,
            Vue3Lottie
        },
        data() {
            return {
                animationState: {
                    line: false,
                    line2: false,
                },
                LineBlue,
                LineBlue2

            }
        },
        setup() {
            const { t, locale } = useI18n({ useScope: 'global' })
            return { t, locale }
        },
        methods: {
            onChange(waypointState) {
                if (this.animationState[waypointState.el.id] === false) {
                    this.animationState[waypointState.el.id] = waypointState.going == 'IN'
                }
            }
        }
    }


</script>


<style scoped lang="scss">
    .meetings-iframe-container {
        border: 0;
    }

    .grid-2 {
        padding: 100px 0px;
    }

    .actions {
        margin-top: 18px;
        margin-bottom: 18px;
        padding: 0px;
    }

    .automatically-box .content {
        width: 48%;
    }

    .content.demo.grid {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width:900px) {
        .grid-2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: column;
            padding: 0px 50px;
            margin-bottom:100px;

        }

        .automatically-box .content {
            width: 100%;
            padding: 0px 50px;
            margin: 0px;
        }
    }

    @media screen and (max-width:450px) {
        .content.demo.grid {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: left;
            padding: 0px;
        }
    }
</style>